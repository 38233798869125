<template>
    <Modal name="modal-privacy-policy" extra-content-class="simple-text-modal">
        <p>„Tušti narvai“ yra organizacija, rengianti kampanijas. Kovojame už gyvūnus, jų teises ir jų gerovę. Organizacijos tikslas – mobilizuoti žmones tam, būtų pasiekti įvairūs gyvūnams naudingi rezultatai. Orientuojamės į įstatyminius pokyčius, įmonių įsipareigojimus bei visuomenės švietimą. Jūsų vaidmuo čia – esminis. Be Jūsų viso to nepasieksime.</p>

        <br>

        <p>Galimybė susisiekti su Jumis bei kitais organizaciją palaikančiais bei remiančias žmonėmis labai svarbi. Siunčiame el. laiškus ir žinutes bei skambiname telefonu. Lėšų rinkimas ir aktyvizmas yra neatskiriama komunikacijos dalis – mes stengiamės skatinti žmones elgtis pagal jų vertybes, prašydami jų daryti daugiau dėl gyvūnų.</p>

        <br>

        <p>Dvi svarbios taisyklės:</p>

        <p>– Tol, kol turime Jūsų leidimą, mes jus informuosime apie tai, kaip dėl gyvūnų galite padaryti daugiau.</p>
        <p>– Jūs visada galite nutraukti savo leidimą, paspaudę nuorodą bet kuriame mūsų siunčiamame el. laiške.</p>

        <br>

        <p><strong>Bendrosios nuostatos</strong></p>
        <p>Ši privatumo politika (toliau – politika) reglamentuoja VšĮ „Tušti narvai“ (toliau – mes, duomenų tvarkytojas, organizacija) organizacijos svetainių (toliau – svetainės, sistema) naudojimosi taisykles bei organizacijos vykdomą asmens (toliau – asmuo, subjektas) duomenų saugojimą ir tvarkymą.</p>
        <p>Jūsų asmens duomenų rinkimą, tvarkymą ir saugojimą nustato ši politika, Lietuvos Respublikos Asmens duomenų teisinės apsaugos įstatymas ir kiti teisės aktai.</p>
        <p>Politika remiasi ir galioja kartu su aukščiau minėtais aktais bei Bendruoju duomenų apsaugos reglamentu (BDAR) – (EU) 2016/679.</p>

        <br>

        <p><strong>Duomenų tvarkymo politika</strong></p>
        <p>Duomenų tvarkymo tikslai:</p>
        <ul>
            <li>– naujienlaiškių siuntimas ir pagalbos prašymas;</li>
            <li>– statistikos rinkimas ir vertinimas:</li>
            <li>– naujienlaiškių atsidarymas ir nuorodų paspaudimas;</li>
            <li>– svetainių puslapių lankomumas;</li>
            <li>– paramos surinkimas bei įmokų už prekes ar paslaugas užskaitymas.</li>
        </ul>

        <br>

        <p><strong>Duomenų saugojimo laikotarpis</strong></p>
        <p>Renkami duomenys saugomi neribotą laikotarpį, nes yra naudojami norint susisiekti su duomenų teikėjais, siųsti padėkas už paramą, spręsti galimai kylančius ginčus ir kt.</p>

        <br>

        <p><strong>Duomenų subjektų teisės</strong></p>
        <p>Asmuo, naudojantis „Tuščių narvų“ sistemas, turi teises:</p>
        <p>– susipažinti su apie jį sukaupta informacija;</p>
        <p>– reikalauti ištaisyti klaidingus duomenis;</p>
        <p>– reikalauti ištrinti duomenis;</p>
        <p>– nutraukti prenumeratą paspaudę nuorodą „Tuščių narvų“ el. laiškuose;</p>
        <p>Norėdami susipažinti, pakeisti arba ištrinti apie jus turimus duomenis, prašome kreiptis e. paštu info@tustinarvai.lt arba telefonu +370 689 29488.</p>

        <br>

        <p><strong>Slapukai (angl. cookies)</strong></p>
        <p>Naudojantis sistema, Jūsų kompiuteryje yra įrašomi slapukai šiems tikslams:</p>
        <p>– būtinajam sistemos veikimui;</p>
        <p>– sistemos lankomumui vertinti;</p>
        <p>– įsiminti jūsų parinktis.</p>
        <p>Slapukai gali būti bet kada peržiūrėti, išvalyti ar užblokuoti naudojantis naršyklės įrankiais.</p>
        <p>Svarbesni slapukai yra šifruojami, taip užtikrinant aukštesnį saugumą.</p>
        <p>Naudojantis sistema Jūs sutinkate, kad bus naudojami minėti slapukai.</p>

        <br>

        <p><strong>Sutikimas</strong></p>
        <p>Tęsdami naršymą sistemai priklausančiose svetainėse sutinkate su slapukų naudojimu.</p>
        <p>Pateikdami asmens duomenis, vaikai iki 16 metų privalo turėti tėvų sutikimą. Kitu atveju jiems pateikti duomenis draudžiama. Jeigu nustatome, kad asmuo iki 16 pateikė savo asmens duomenis, juos ištriname.</p>

        <br>

        <p><strong>Asmens duomenų perdavimas trečiosioms šalims</strong></p>
        <p>Duomenų tvarkytojas turi teisę perduoti asmeninę informaciją trečiosioms šalims tik Lietuvos Respublikos įstatymų nustatyta tvarka.</p>

        <br>

        <p><strong>Papildomos saugumo priemonės</strong></p>
        <p>Sistemoje įdiegtas SSL sertifikatas, todėl ryšys tarp Jūsų naršyklės ir sistemos serverio yra šifruotas. Sistema naudojantis net esant prisijungus prie neapsaugotų WiFi tinklų, Jūsų asmeniniai duomenys matomi nebus.</p>

        <br>

        <p><strong>Baigiamosios nuostatos</strong></p>
        <p>Dėl kokių nors priežasčių kilus ginčams ir nepavykstant jų išspręsti tarpusavio sutarimu, ginčai sprendžiami Lietuvos Respublikos įstatymų nustatyta tvarka.</p>
        <p>„Tušti narvai“ pasilieka teisę keisti šią privatumo politiką.</p>

        <br>

        <p>Jeigu turite klausimų, laukiame jų e. paštu <a href="mailto:info@tustinarvai.lt" class="underline">info@tustinarvai.lt</a> arba telefonu +370 689 29488.</p>
    </Modal>
</template>
